import { Component } from 'react';
import './Why.css';

let CMP

let structure = {
    section_1 : {
        image : 'images/why/section_1.png',
        title : 'Give your customers a unique experience',
        items : [
            'Reach your customers creatively.',
            'A more personalized experience for your customers.',
            'Consumers can experience your Product from wherever they are.',
            'No need to download any apps.'
        ]
    },
    section_2 : {
        image : 'images/why/section_2.png',
        title : 'Enrich your adverts',
        items : [
            'An advertising campaign that can stand out in a sea of nondescript adverts. ',
            'Step up your marketing strategy with state-of-the-art technology.',
            'A great way to drive traffic to your online channels.',
            'Plug and play – run your campaign in a few hours.'
        ]
    },
    section_3 : {
        image : 'images/why/section_3.png',
        title : 'Get in touch with the best in the industry',
        items : [
            'No time or money wasted on additional app development.',
            'No set-up fee.',
            'Pay only a monthly subscription.',
            'Clear your queries at the 24x7 technical help desk.',
            'Get assistance and guidance on concepts and creative aspects.'
        ]
    }
}

class Why extends Component {

    constructor(props) {
        super(props)
        CMP = this
        this.state = { mobi : 0 }
    }

    buildSection(n) {
        let out = [];
        Object.values(structure)[n].items.forEach(function(itm, i) {
            out.push(
                <div className="WhySectionItem" key={i}>{ itm }</div>
            );
        });
        return out;
    };

    buildInner(_this) {
        let out = [];
        Object.values(structure).forEach(function(obj, i) {
            out.push(
                <div className={ "WhySectionRoot WhySectionRoot_" + i } key={i}>
                    <div className="WhySection" key={i}>
                <div className="WhyTitle">Why WynkAR?</div>
                        <div
                            className="WhySectionImage"
                            style={ { backgroundImage : 'url('+ obj.image +')' } }
                        ></div>
                        <div className="WhySectionTitle">
                            { obj.title }
                        </div>
                        {  _this.buildSection(i) }
                    </div>
                </div>
            );
        });
        return out;
    };


    buildSectionMobi(n) {
        let out = [];
        Object.values(structure)[n].items.forEach(function(itm, i) {
            out.push(
                <div className="WhySectionItem" key={i}>{ itm }</div>
            );
        });
        return out;
    };

    buildInnerMobi(_this) {
        let out = [];
        Object.values(structure).forEach(function(obj, i) {
            out.push(
                <div className="WhySection" key={i}>
                    <div
                        className="WhySectionImage"
                        style={ { backgroundImage : 'url('+ obj.image +')' } }
                    ></div>
                    {  _this.buildSectionMobi(i) }
                </div>
            );
        });
        return out;
    };

	render() {

        if(window.innerHeight > window.innerWidth) {
            return (
                <div className="WhyRoot">
                <div className="WhyRootInner">
                    <div className="WhyTitle">Why WynkAR?</div>
    
                    <div className="WhyInner">{
                        this.buildInnerMobi(this)
                    }</div>
                </div>
                <div className="WhySectionRoot_0"></div>
                <div className="WhySectionRoot_1"></div>
                <div className="WhySectionRoot_2"></div>
                </div>
            );
    
        }

		return (
            <div className="WhyRoot">
            <div className="WhyRootInner">
                <div className="WhyInner">{
                    this.buildInner(this)
                }</div>
                </div>
            </div>
        );
	};

};

window.addEventListener('resize', () => {
    CMP.setState({ mobi : Date.now() })
})

export default Why;