/**
 *  ScrollJS v3.2 by Deshan Nawanjana
 *  https://dnjs.info/
 *  deshan.nawanjana@arimaclanka.com
 *  Copyright © 2021 Deshan Nawanjana <dnjs.info@gmail.com>
**/

import gsap from 'gsap';

let getScrollTopArray = function(err) {
    let trr = [];
	err.forEach(function(elem) {
        elem = document.querySelector(elem)
		trr.push(elem.getBoundingClientRect().top);
	});
    return trr;
};

let getCloserElement = function(err, idx = false) {
    let trr = getScrollTopArray(err);
    let cls = trr.reduce(function(prev, curr) {
		return (Math.abs(curr - 0) < Math.abs(prev - 0) ? curr : prev);
	});
    if(idx) { return trr.indexOf(cls) } else { return err[trr.indexOf(cls)]; }
};

let setElement = function(elem, data) {
    if(window.innerWidth < window.innerHeight) { return }
    if(typeof elem === 'string') {
        elem = document.querySelector(elem)
    }
    if(elem === undefined) { return; }
    if(elem === null) { return; }
    if(data.isactive && data.overlaps === false) { return; }
    let doc = document.documentElement;
    let gap = elem.getBoundingClientRect().top;
	let top = doc.scrollTop;
	let a = { x : top };
	let b = { x : top + gap, duration : data.duration / 1000, ease : data.easetype };
	gsap.timeline({
		onStart    : function() { data.isactive = true; },
		onComplete : function() { data.isactive = false; },
		onUpdate   : function() { doc.scrollTop = a.x; }
	}).fromTo(a, a, b);
};

let getNegativeMax = function(arr) {
	let k = null;
	arr.forEach(function(e) { if(e < 0 && (k < e || k === null)) { k = e; } });
	return arr.indexOf(k);
};

let getPositiveMin = function(arr) {
	let k = null;
	arr.forEach(function(e) { if(e > 0 && (k > e || k === null)) { k = e; } });
	return arr.indexOf(k);
};

let getAlmostZero = function(arr) {
	let k = null;
	arr.forEach(function(e, i) {
		if(e === 0) { k = e; } else if(e - 20 < 0 && e + 20 > 0) { k = e; }
	});
	return arr.indexOf(k);
};

let getNextAndBackElements = function(err) {
    let trr = getScrollTopArray(err);
	let lst = getNegativeMax(trr);
	let nxt = getPositiveMin(trr);
	let zro = getAlmostZero(trr);
	// if any element on zero position
	if(zro !== -1) { lst = zro - 1; nxt = zro + 1; }
    return { next : err[nxt], back : err[lst] }
};

let selectCloseElementCallbacks = function(data) {
    let crr = getCloserElement(data.elements, true);
    data.styleset.forEach(function(obj, i) {
        let elm = document.querySelector(data.elements[i]);
        if(crr === i) {
            if(typeof obj === 'function') {
                obj();
            } else if(typeof obj === 'string') {
                elm.classList.add(obj);
            } else if(Array.isArray(obj)) {
                obj.forEach(function(e) { elm.classList.add(e); });
            } else {
                sendCloseElementCallbacks(elm, obj.on, obj.off);
            }
        } else {
            if(typeof obj === 'string') {
                elm.classList.remove(obj);
            } else if(Array.isArray(obj)) {
                obj.forEach(function(e) { elm.classList.remove(e); });
            } else {
                sendCloseElementCallbacks(elm, obj.off, obj.on);
            }
        }
    });
};

let sendCloseElementCallbacks = function(elm, add, rem) {
    if(add) {
        if(typeof add === 'function') { add(); }
        if(typeof add === 'string') { elm.classList.add(add); }
        if(Array.isArray(add)) {
            add.forEach(function(e) { elm.classList.add(e); });
        }
        if(typeof add === 'object' && Array.isArray(add) === false) {
            Object.keys(add).forEach(function(prop) {
                elm.style[prop] = add[prop];
            });
        }
    }
    if(rem && typeof rem === 'string') { elm.classList.remove(rem); }
    if(rem && Array.isArray(rem)) {
        rem.forEach(function(e) { elm.classList.remove(e); });
    }
};

let ScrollJS = function(object, duration = 700, ease = 'back', overlap = true) {

    this.enabled = true;

    var data = {
        elements : [],
        styleset : [],
        duration : duration,
        easetype : ease,
        overlaps : overlap,
        isactive : false
    };

    Object.keys(object).forEach(function(selector) {
        data.elements.push(selector);
        data.styleset.push(object[selector]);
    });

    this.getNear = function() { return getCloserElement(data.elements); };
    this.getNext = function() { return getNextAndBackElements(data.elements).next; };
    this.getBack = function() { return getNextAndBackElements(data.elements).back; };
    this.setNext = function() { this.setThis(this.getNext(), data); };
    this.setNear = function() { this.setThis(getCloserElement(data.elements), data); };
    this.setBack = function() { this.setThis(this.getBack(), data); };
    this.setThis = function(element) { setElement(element, data); };

    let _this = this;

    window.addEventListener('mouseup', function(event) {
        if(_this.enabled === false) { return; }
	    if(window.innerHeight > window.innerWidth) { return }
        if(event.path.indexOf(document.querySelector('.ContactRoot')) > -1) { return }
        _this.setNear();
    });

    let root = document.querySelector('#root');

    root.addEventListener("wheel", function(e) {

        let ign = [
            'ChatBotTray',
            'ChatBotAIMessageOuter',
            'ChatBotAIMessage',
            'ChatBotUserMessageOuter',
            'ChatBotUserMessage'
        ]

        if(ign.indexOf(e.target.className) > -1) { return; }

        if(_this.enabled === false) { return; }
        e.preventDefault();
        if(e.deltaY < 0) { _this.setBack(); }
        if(e.deltaY > 0) { _this.setNext(); }
    }, { passive : false });

    window.addEventListener('keydown', function(e) {
        if(_this.enabled === false) { return; }
        let krr = ['PageUp', 'PageDown', 'Home', 'End'];
        if(krr.indexOf(e.key) > -1) {
            e.preventDefault();
            if(e.key === 'PageUp'  || e.key === 'Home') { _this.setBack(); }
            if(e.key === 'PageDown' || e.key === 'End') { _this.setNext(); }
        }
    });

    window.addEventListener('scroll', function() {
        if(window.innerWidth > window.innerHeight) {
            if(_this.enabled === false) { return; }
            selectCloseElementCallbacks(data);
        }
    });

    selectCloseElementCallbacks(data);

};

export default ScrollJS;