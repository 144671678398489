import {
    checkControls,
    checkConsole,
    checkTransform,
    checkObjects
} from "./_check";

import {
    createScene,
    createEffect,
    createEvent,
    createLight,
    createObject,
    createParticles
} from './_create';

import {
    exportModel
} from './_export';

import {
    findAll,
    findById,
    findByName,
    findByType,
    findByProperty,
    findByMaterialName,
    findByMaterialProperty
} from './_find';

import {
    evalPoints
} from './_eval';

import {
    loadAll,
    loadAjax,
    loadImage,
    loadBitmap,
    loadModel,
    loadTexture
} from './_load';

import {
    setupControls,
    setupObject,
    setupScene,
    setupMaterial
} from './_setup';

import {
    tweenAnimation,
    tweenControls,
    tweenMaterial,
    tweenObject,
    tweenLight
} from './_tween';

let threejs = {
    checkControls : checkControls,
    checkConsole : checkConsole,
    checkTransform : checkTransform,
    checkObjects : checkObjects,
    exportModel : exportModel,
    tweenAnimation : tweenAnimation,
    createScene : createScene,
    createEffect : createEffect,
    createEvent : createEvent,
    createObject : createObject,
    createLight : createLight,
    createParticles : createParticles,
    evalPoints : evalPoints,
    findAll : findAll,
    findById : findById,
    findByName : findByName,
    findByType : findByType,
    findByProperty : findByProperty,
    findByMaterialName : findByMaterialName,
    findByMaterialProperty : findByMaterialProperty,
    loadAll : loadAll,
    loadAjax : loadAjax,
    loadImage : loadImage,
    loadBitmap : loadBitmap,
    loadModel : loadModel,
    loadTexture : loadTexture,
    setupControls : setupControls,
    setupObject : setupObject,
    setupScene : setupScene,
    setupMaterial : setupMaterial,
    tweenControls : tweenControls,
    tweenMaterial : tweenMaterial,
    tweenObject : tweenObject,
    tweenLight : tweenLight
};

export { threejs };