import { Component } from 'react';
import './About.css';

class About extends Component {

	render() {
		return (
            <div className="AboutRoot">
            <div className="AboutRootInner">
                <div className="AboutCanvas">
                    <div className="AboutMobileImage">
                        <div className="AboutMobileFrame">
                            
                        </div>
                    </div>
                </div>
                <div className="AboutContent">
                    <div className="AboutContentTitle">
                        About  WynkAR
                    </div>
                    <div className="AboutContentParag">
                        WynkAR is a revolutionary portal that allows you to reimagine and reinvent your adverts and product showcases by using Augmented Reality (AR), taking your day-to-day marketing and sales operations a step ahead.
                        <br /><br />
                        Developed by digital disruptor Arimac, WynkAR is a world-class, affordable AR solution that is being used by leading brands across the globe.
                    </div>
                </div>
            </div>
            </div>
        );
	};

};

window.addEventListener('load', function() {
    let root = document.querySelector('.AboutRoot');
    let elem = document.querySelector('.AboutMobileImage');

    function letGo() {  elem.removeAttribute('style'); };

    root.addEventListener('mousemove', function(e) {
        let x = e.clientX;
        if(x < window.innerHeight - 180) {

            let pt = x / (window.innerHeight - 180);

            let rm = window.innerHeight / 2 - 100;
            elem.style.marginLeft = parseInt(50 + pt * rm) + 'px';

            let rb = 100 - window.innerHeight / 2;
            elem.style.backgroundPosition = parseInt(pt * rb) + 'px 0px';

            elem.style.animationName = 'none';
        } else { letGo(); }
    });

    root.addEventListener('mouseout', letGo);
    root.addEventListener('mouseleave', letGo);

});

export default About;