import { Component } from 'react';
import './Navbar.css';

let struct = {
    "About"       : { root : '.AboutRoot' },
    "Portfolio"   : { root : '.PortfolioRoot' },
    "Why WynkAR?" : { root : '.WhyRoot' },
    // "Blog"        : { root : 'blog/' },
    "Contact Us"  : { root : '.ContactRoot' }
}

class Navbar extends Component {

    buildNavbarMenu() {
        let arr = [];
        Object.keys(struct).forEach(function(label) {
            arr.push(
                <div
                    className="NavbarMenuItem"
                    key={label}
                    lang={struct[label].root}
                    onClick={scrollTo}
                >{ label }</div>
            );
        });
        return arr;
    };

	render() {
		return [
			<div key="1" className="NavbarRoot NavbarRootTop">
                <div className="NavbarLogo" onClick={scrollTop}></div>
				<div className="NavbarMenu">
                    { this.buildNavbarMenu() }
                </div>
			</div>,
            <div key="2" className="NavbarSpace"></div>,
            <div key="3" className="NavbarTop" onClick={scrollTop}></div>
        ];
	};

};

window.addEventListener('scroll', function() {
    let x = document.documentElement.scrollTop;
    // navbar ribbon
    let q = document.querySelector('.NavbarRoot');
    if(x > 10) {
        q.classList.remove('NavbarRootTop');
    } else { q.classList.add('NavbarRootTop'); }
    // navbar top button
    let t = document.querySelector('.NavbarTop');
    if(x < window.innerHeight - 200) {
        t.classList.remove('NavbarTopOn');
    } else { t.classList.add('NavbarTopOn'); }
});

function scrollTo(e) {
    if(e.target.lang.indexOf('/') > -1) {
        window.open(e.target.lang)
    } else {
        let element = document.querySelector(e.target.lang);
        if(window['ScrollJS']) {
            window['ScrollJS'].setThis(element);
        }
    }
};

function scrollTop() {
    let element = document.querySelector('.NavbarSpace');
    if(window['ScrollJS']) {
        window['ScrollJS'].setThis(element);
    }
}

export default Navbar;