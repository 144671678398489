import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './modules/App';
import Navbar from './modules/Navbar';
import Cover from './modules/Cover';
import ChatBot from './modules/ChatBot';
import About from './modules/About';
import Portfolio from './modules/Portfolio';
import Why from './modules/Why';
import Contact from './modules/Contact';
import reportWebVitals from './reportWebVitals';
import Started from './modules/Started';
import Global from './modules/Global';

ReactDOM.render(
  <React.StrictMode>

    <App />
    <ChatBot />

    <Navbar />
    <Cover />
    <About />
    <Portfolio />
    <Started />
    <Why />
    <Global />
    <Contact />



  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();