//import { _args } from '../import';
import './index.css';

let _args = function(arr) {
	var out = { "obj" : [], "str" : [], "num" : [], "fun" : [], "boo" : [] };
	if(arr === undefined) { return out; }
	for(var i = 0; i < arr.length; i++) {
		var arg = arr[i];
		var typ = (typeof arg).substr(0, 3);
		if(out[typ] === undefined) { out[typ] = []; }
		out[typ].push(arg);
	}
	return out;
};


// import model-viewer script once
let initialize = function() {
    if(initialize.done) { return; }
    let e = document.createElement('script');
    //let j = 'https://unpkg.com/@google/model-viewer@1.8.0/dist/model-viewer-umd.js';
    let j = 'libs/model-viewer-umd.js';
    e.setAttribute('src', j);
    document.head.appendChild(e);
    initialize.done = true;
}; initialize.done = false;

let createView = function() {
    // initialize
    //initialize();
    let args = _args(arguments);
    let source = args.obj[0] || {};
    // create element
    let mv = document.createElement('model-viewer');
    // sources
    mv.setAttribute('src', source.src);
    mv.setAttribute('ios-src', source.ios);
    // options
    mv.setAttribute('ar', true);
    mv.setAttribute('ar-modes', 'webxr');
    mv.setAttribute('ar-placement', 'floor');
    mv.setAttribute('camera-controls', true);
    return mv;
};





























let modelviewer = {
    createView
};

export { modelviewer };