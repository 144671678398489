import { threejs } from ".";

let checkControls = function(controls) {
    let t = controls.target;
    let c = controls.object.position;
    window.addEventListener('keydown', function(e) {
        if(e.key === '`') {
            let x = '';
            x += '{\n';
            x += '  target : { x : '+ t.x +', y : '+ t.y +', z : '+ t.z +' },\n';
            x += '  camera : { x : '+ c.x +', y : '+ c.y +', z : '+ c.z +' },\n';
            x += '}';
            console.log(x);
        };
    });
};

let checkTransform = function(object) {
    let p = object.position;
    let r = object.rotation;
    let s = object.scale;
    window.addEventListener('keydown', function(e) {
        
        if(e.key === '`') {
            let x = '';
            x += '{\n';
            x += '  position : { x : '+ p.x +', y : '+ p.y +', z : '+ p.z +' },\n';
            x += '  rotation : { x : '+ r.x +', y : '+ r.y +', z : '+ r.z +' },\n';
            x += '  scale    : { x : '+ s.x +', y : '+ s.y +', z : '+ s.z +' },\n';
            x += '}';
            console.log(x);
        };

        let speed = 0.001;
        if(e.shiftKey) { speed = 0.01; e.preventDefault(); }
        if(e.ctrlKey)  { speed = 0.1; e.preventDefault(); }

        if(e.key === '+') {
            object.scale.set(
                object.scale.x + speed,
                object.scale.y + speed,
                object.scale.z + speed
            );
        }

        if(e.key === '-') {
            object.scale.set(
                object.scale.x - speed,
                object.scale.y - speed,
                object.scale.z - speed
            );
        }

        if(e.key === '*') {
            object.rotation.set(
                object.rotation.x,
                object.rotation.y + Math.PI / 4,
                object.rotation.z
            );
        }

        if(e.key === '/') {
            object.rotation.set(
                object.rotation.x,
                object.rotation.y - Math.PI / 4,
                object.rotation.z
            );
        }

        if(e.key === '2') { object.position.z += speed; }
        if(e.key === '8') { object.position.z -= speed; }
        if(e.key === '6') { object.position.x += speed; }
        if(e.key === '4') { object.position.x -= speed; }

    });
};

let checkObjects = function(object, property) {
    threejs.findByType(object, 'Mesh', true).forEach(function(mesh) {
        threejs.createEvent('click', mesh, function(e) {
            if(property !== undefined) {
                console.log(e.object[property], e.object, e);
            } else { console.log(e.object, e); }
        });
    });
};



let checkConsole = function() {
    if(window.innerWidth > window.innerHeight) { return; }
    var e = document.createElement('div');
    e.style.position = 'fixed';
    e.style.zIndex = 100;
    e.style.width = 'calc(100vw - 20px)';
    e.style.height = '112px';
    e.style.left = '0px';
    e.style.top = '0px';
    e.style.background = '#222';
    e.style.color = '#ffffff';
    e.style.fontFamily = 'Consolas';
    e.style.fontSize = '10px';
    e.style.lineHeight = '16px';
    e.style.overflow = 'hidden';
    e.style.padding = '10px'
    document.body.style.marginTop = '130px';
    document.body.appendChild(e);

    console.log = function() {
        var args = Array.from(arguments);
        e.innerHTML = checkConsole.getString(args, '#0099ff') + e.innerHTML;
        checkConsole.removeOverflow();
    };

    console.error = function() {
        var args = Array.from(arguments);
        e.innerHTML = checkConsole.getString(args, '#ff0000') + e.innerHTML;
        checkConsole.removeOverflow();
    };

    console.warn = function() {
        var args = Array.from(arguments);
        e.innerHTML = checkConsole.getString(args, 'yellow') + e.innerHTML;
        checkConsole.removeOverflow();
    };

};

checkConsole.getString = function(args, color) {
    var k = '';
    args.forEach(function(a) {
        k += JSON.stringify(a) + ' ';
    });
    var e = document.createElement('div');
    e.className = 'trinity-log';
    e.style.lineHeight = '16px';
    e.style.color = color;
    e.innerHTML = k;
    return e.outerHTML;
};

checkConsole.removeOverflow = function() {
    var arr = document.querySelectorAll('.trinity-log');
    if(arr === null) { return; }
    for(var i = arr.length - 1; i > 6; i--) {
        arr[i].outerHTML = '';
    };
};

export {
    checkControls,
    checkConsole,
    checkTransform,
    checkObjects
};