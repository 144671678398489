import * as THREE from "three";
import gsap from 'gsap';
// loaders
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { DDSLoader } from 'three/examples/jsm/loaders/DDSLoader';
// controls
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls';
// postprocessing
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
import { BloomPass } from "three/examples/jsm/postprocessing/BloomPass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { SSAOPass } from "three/examples/jsm/postprocessing/SSAOPass";
import { SSRPass } from "three/examples/jsm/postprocessing/SSRPass";
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass";
import { BokehPass } from "three/examples/jsm/postprocessing/BokehPass";
import { ReflectorForSSRPass } from "three/examples/jsm/objects/ReflectorForSSRPass";
// shaders
import { FilmShader } from "three/examples/jsm/shaders/FilmShader";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import { SSAOShader } from "three/examples/jsm/shaders/SSAOShader";
// exporters
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { USDZExporter } from "three/examples/jsm/exporters/USDZExporter";
// gui
import { GUI } from "three/examples/jsm/libs/dat.gui.module";

import { Clock } from "three";

let POSTPROCESSING = {
    EffectComposer : EffectComposer,
    RenderPass : RenderPass,
    ShaderPass : ShaderPass,
    OutlinePass : OutlinePass,
    BloomPass : BloomPass,
    UnrealBloomPass : UnrealBloomPass,
    GlitchPass : GlitchPass,
    SSAOPass : SSAOPass,
    SSRPass : SSRPass,
    ReflectorForSSRPass : ReflectorForSSRPass,
    FilmPass : FilmPass,
    BokehPass : BokehPass
};

let SHADERS = {
    FilmShader : FilmShader,
    FXAAShader : FXAAShader,
    SSAOShader : SSAOShader
};

let LOADERS = {
    GLTFLoader : GLTFLoader,
    FBXLoader : FBXLoader,
    OBJLoader : OBJLoader,
    MTLLoader : MTLLoader,
    DDSLoader : DDSLoader
};

let CONTROLS = {
    OrbitControls : OrbitControls,
    PointerLockControls : PointerLockControls
};

let EXPORTERS = {
    GLTFExporter : GLTFExporter,
    USDZExporter : USDZExporter
};

let GSAP = gsap;
let CLOCK = Clock;

let _args = function(arr) {
	var out = { "obj" : [], "str" : [], "num" : [], "fun" : [], "boo" : [] };
	if(arr === undefined) { return out; }
	for(var i = 0; i < arr.length; i++) {
		var arg = arr[i];
		var typ = (typeof arg).substr(0, 3);
		if(out[typ] === undefined) { out[typ] = []; }
		out[typ].push(arg);
	}
	return out;
};

let createElement = function() {
    let args = _args(arguments);
    let name = args.str[0] || '';
    let html = args.str[1] || '';
    let root = args.obj[0] || null;
    let e = document.createElement('div');
    e.setAttribute('class', name);
    e.innerHTML = html;
    if(root) { root.appendChild(e); }
    return e;
};

export {
    THREE,
    LOADERS,
    CONTROLS,
    POSTPROCESSING,
    SHADERS,
    EXPORTERS,
    GSAP,
    CLOCK,
    GUI,
    _args,
    createElement
};