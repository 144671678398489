import { Component } from 'react';
import './ChatBot.css';
import ChatJS from '../scripts/Chat';

// ========================================================================

class ChatBot extends Component {

	render() {
		return (
            <div className="ChatBotRoot">
                <div className="ChatBotOuter">
                    <div className="ChatBotHover" onClick={toggleChatBot}>
                        <div className="ChatBotHoverClose"></div>
                        WynkAR ChatBot
                    </div>
                    <div className="ChatBotInner">
                        <div className="ChatBotTray">
                            <div className="ChatBotAIMessageOuter">
                            <div className="ChatBotAIMessage">
                                Hi! I'm WynkAR ChatBot.<br />How can I help you?
                            </div>
                            </div>
                        </div>
                        <div className="ChatBotSelectBox">
                            <div className="ChatBotSelect">About WynkAR</div>
                            <div className="ChatBotSelect">Contact Details</div>
                            <div className="ChatBotSelect">What is AR?</div>
                            <div className="ChatBotSelect">Portfolio</div>
                        </div>
                        <input
                            onKeyDown={inputMessage}
                            onKeyUp={checkInputState}
                            className="ChatBotText"
                            type="text" placeholder="Type Your Message or Select"
                        />
                    </div>
                </div>
            </div>
        );
	};

};

// ========================================================================

let chatjs = new ChatJS();

chatjs.add('WynkAR is a revolutionary portal that allows you to reimagine and reinvent your adverts and product showcases by using Augmented Reality.', {
    keylist : ['about', 'wynkar', 'wynk', 'brief'],
    queries : [
        'about wynkar',
        'about wynk ar',
        'about company',
        'about business',
        'wynkar do'
    ],
    addtion : { root : '.AboutRoot' }
});

chatjs.add('Our hotline is: <b>(+94) 115 219 554</b>, Also you can contact us via e-mail: <b>hello@arimac.digital</b>', {
    keylist : ['contact', 'inqueries', 'demo'],
    queries : [
        'contact details',
        'contact info',
        'more information',
        'how to contact',
        'i need a demo'
    ]
});

chatjs.add('You can contact us via our hotline : <b>(+94) 115 219 554</b>', {
    keylist : ['hotline', 'number', 'phone', 'call', 'mobile', 'mob', 'num', 'tel'],
    queries : [
        'contact number',
        'mobile number',
        'office hotline'
    ]
});

chatjs.add('You can send an e-mail to: <b>hello@arimac.digital</b>', {
    keylist : ['mail', 'email'],
    queries : [
        'send mail',
        'send email',
        'email address',
        'mail address'
    ]
});

chatjs.add('Our address is: Arimac Digital No 02, 6 th Lane, Colombo 03, Sri Lanka.', { 
    keylist : ['location', 'address', 'located', 'visit'],
    queries : [
        'company address',
        'official address',
        'business location',
        'local address'
    ]
}); 

chatjs.add('Augmented Reality (AR) is an enhanced version of the real physical world that is achieved through the use of digital visual elements, sound, or other sensory stimuli delivered via technology.', {
    keylist : ['ar', 'augmented', 'reality', 'technology'],
    queries : [
        'what is ar',
        'what is augmented reality',
        'ar means',
        'ar augmented reality',
        'ar explain',
        'augmented reality explain',
        'what is this technology'
    ]
});

chatjs.add('We have done some pretty amazing AR and VR porfolios. You may able to see some of them now. Click them to view.', {
    keylist : ['portfolio', 'sample', 'product', 'example'],
    queries : [
        'show portfolio',
        'give samples',
        'product samples',
        'uses',
        'use cases'
    ],
    addtion : { root : '.PortfolioRoot' }
}); 

chatjs.add('You can visit our blog section to read and learn more about AR technology.', {
    keylist : ['blog', 'learn', 'blogging', 'blogger', 'educate'],
    queries : [
        'blog page',
        'learn ar',
        'educate augmented reality',
        'learn technology'
    ],
    addtion : { root : '.BlogRoot' }
});

chatjs.add('Hi! I\'m WynkAR ChatBot.<br>How can I help you?', {
    keylist : ['hi', 'hay', 'hello'],
    queries : [
        'how are you',
        'who are you',
        'your name',
    ]
});

chatjs.add('Our clients are .', {
    keylist : ['client', 'brand', 'companies'],
    queries : [
        'who are the clients',
        'what are the clients',
        'what are the brands',
        'current clients',
        'local clients',
        'international clients'
    ]
});

// ========================================================================

let isChatOn = false;

function toggleChatBot() {
    let hover = document.querySelector('.ChatBotHover');
    let outer = document.querySelector('.ChatBotOuter');
    isChatOn = !isChatOn;
    if(isChatOn) {
        hover.classList.add('ChatBotHoverOn');
        outer.classList.add('ChatBotOuterOn');
        scrollToBottom(0);
        if(window.innerHeight < window.innerWidth) {
            document.querySelector('.ChatBotText').focus();
        }
    } else {
        outer.classList.remove('ChatBotOuterOn');
        hover.classList.remove('ChatBotHoverOn');
    }
};

function inputMessage(e) {
    if(e.key === 'Enter') {
        let txt = e.target.value;
        if(chatjs.validate(txt)) {
            let obj = chatjs.ask(txt);
            if(obj === null) {
                applyMessages(txt, 'Sorry, I didn\'t understand that.');
            } else {
                let s = null;
                if(obj.addtion) { s = obj.addtion.root; }
                console.log(s);
                applyMessages(txt, obj.answer, s);
            }
        }
        e.target.value = '';
    }
};

function checkInputState(e) {
    let t = document.querySelector('.ChatBotInner');
    if(e.target.value === '') {
        t.classList.remove('ChatBotInnerInput');
        scrollToBottom(400);
    } else {
        t.classList.add('ChatBotInnerInput');
        scrollToBottom(400);
    }
};

function applyMessages(q, a, s) {
    let tray = document.querySelector('.ChatBotTray');

    let outer = document.createElement('div');
    outer.className = 'ChatBotUserMessageOuter';
    let inner = document.createElement('div');
    inner.className = 'ChatBotUserMessage';
    inner.innerHTML = q;
    outer.appendChild(inner);
    tray.appendChild(outer);

    scrollToBottom();

    setTimeout(function() {
        outer = document.createElement('div');
        outer.className = 'ChatBotAIMessageOuter';
        inner = document.createElement('div');
        inner.className = 'ChatBotAIMessage';
        inner.innerHTML = a;
        outer.appendChild(inner);
        tray.appendChild(outer);
        scrollToBottom();
        scrollTo(s);
    }, 600);
};

function scrollToBottom(t = 50) {
    let tray = document.querySelector('.ChatBotTray');
    setTimeout(function() {
        tray.scrollTop = tray.scrollHeight + 600;
    }, t);
};

function scrollTo(name) {
    if(name === undefined || name === null) { return; }
    let element = document.querySelector(name);
    if(window['ScrollJS']) {
        window['ScrollJS'].setThis(element);
    }
};

window.addEventListener('load', function() {
    Array.from(document.querySelectorAll('.ChatBotSelect')).forEach(
        function(itm) {
            itm.addEventListener('click', function(e) {
                let obj = chatjs.ask(e.target.innerText);
                let s = null;
                if(obj.addtion) { s = obj.addtion.root; }
                applyMessages(e.target.innerText, obj.answer, s);
            });
        }
    );
})

export default ChatBot;