import { threejs } from './three.js';
import { immersivear } from './immersive-ar';
import { modelviewer } from './model-viewer';

import {
    THREE,
    GSAP,
    GUI,
    POSTPROCESSING,
    SHADERS,
    LOADERS,
    CONTROLS,
    CLOCK,
    createElement
} from './import';

let TrinityEngine = {
    "three.js" : threejs,
    "immersive-ar" : immersivear,
    "model-viewer" : modelviewer
};

export {
    TrinityEngine,
    THREE,
    GSAP,
    GUI,
    POSTPROCESSING,
    SHADERS,
    LOADERS,
    CONTROLS,
    CLOCK,
    createElement
};