import { Component } from 'react';
import './App.css';
import ScrollJS from '../scripts/Scroll';

// ====================================================================================

class App extends Component {
	render() { return []; };
};

// ====================================================================================

window['scrollSection'] = function(e) {
	let navi = document.querySelector('.NavbarRoot');
	let sect = document.querySelector(e);
	let dele = document.documentElement;
	if(navi === null || sect === null) { return; }
	navi = navi.getBoundingClientRect();
	sect = sect.getBoundingClientRect();
	if(dele.scrollTop < 10) {
		// from page top
		dele.scrollTop += sect.top - navi.height + 10;
	} else {
		// from any other position
		dele.scrollTop += sect.top - navi.height;
	}
};

window.addEventListener('load', function() {

	window['ScrollJS'] = new ScrollJS({
		'.NavbarSpace' : {},
		'.AboutRoot' : {},
		'.PortfolioRoot' : {},
		'.StartedRoot' : {},
		'.WhySectionRoot_0' : 'WhySectionOn',
		'.WhySectionRoot_1' : 'WhySectionOn',
		'.WhySectionRoot_2' : 'WhySectionOn',
		'.GlobalRoot' : {},
		// '.BlogRoot' : {},
		'.ContactRoot' : {}
	}, 700, 'power2')

});

window.addEventListener('resize', () => {
	setTimeout(() => {
		window['ScrollJS'].setNear()
	}, 100)
})

export default App;