import { Component } from 'react';
import './Portfolio.css';

let CMP

class Portfolio extends Component {
    constructor (props) {
        super(props);
        CMP = this
        this.state = { index : 0, mobi : 0 }
    };

    itemOpen() {
        window.open(structure[this.state.index].link);
    }

    callOpen() {
        window['ScrollJS'].setThis('.ContactRoot')
    }
    
    getPortfolioName() {
        let obj = structure[this.state.index];
        return (
            <div className="PortfolioName">
                {obj.name}
            </div>
        );
    }
    
    getPortfolioInfo() {
        let obj = structure[this.state.index];
        return ([
            <div className="PortfolioText" key={1}>
                {obj.info}
            </div>,
            <button onClick={this.itemOpen} className="PortfolioDemoButton"
                key={2}>View Portfolio</button>,
                <button onClick={this.callOpen} className="PortfolioCallButton"
                    key={3}>Contact Us</button>
        ]);
    }

    itemOpenMobi(e) {
        window.open(structure[e.target.lang].link)
    }

    buildItems() {
        let arr = [];
        let _this = this
        structure.forEach(function(obj, i) {
            arr.push(
                <div
                    className="PortfolioItem"
                    key={i}
                    style={ { backgroundImage : 'url('+ obj.snap +')' } }
                >
                    <div
                        className="PortfolioItemTitle"
                        onClick={ _this.itemOpenMobi }
                        lang={i}
                    >{ obj.name }</div>
                </div>
            );
        });
        return arr;
    };

    buildGrid() {
        return structure.map((item, index) => {
            return <div
                        className="PortfolioVideoInner"
                        style={{ backgroundImage : `url(${item.snap})` }}
                        key={index}
                        onMouseEnter={() => play(index)}
                        onMouseOut={() => pause(index)}
                        onMouseLeave={() => pause(index)}
                        onClick={() => { this.setState({ index : index }) }}
                    ><video
                        onMouseEnter={() => play(index)}
                        onMouseOut={() => pause(index)}
                        onMouseLeave={() => pause(index)}
                        src={item.play}
                        loop={true}
                        muted={true}
                    ></video>
                    <div
                        onMouseEnter={() => play(index)}
                        onMouseOut={() => pause(index)}
                        onMouseLeave={() => pause(index)}
                        className="PortfolioVideoInfo"
                    >
                        <div
                            className="PortfolioVideoLink"
                            onClick={() => window.open(item.link)}
                        ></div>
                        {item.name}
                    </div>
                </div>
        })
    }

	render() {

        if(window.innerWidth < window.innerHeight) {
            return (
                <div className="PortfolioRoot">
                    <div className="PortfolioTitle">
                        Experience Portfolio
                    </div>
                    <div className="PortfolioInner">
                        { this.buildItems() }
                    </div>
                </div>
            );
        }

		return (
            <div className="PortfolioRoot" index={this.state.index}>
                <div className="PortfolioButton PortfolioButtonLast" onClick={last}></div>
                <div className="PortfolioButton PortfolioButtonNext" onClick={next}></div>
                <div className="PortfolioRootInner">
                    <div className="PortfolioTitle">
                        Experience Portfolio
                    </div>
                    <div
                        className="PortfolioInner PortfolioInnerDesktop"
                        index={this.state.index}
                    >{ this.buildGrid() }</div>
                </div>
            </div>
        );

	};

};

const last = () => {
    if(CMP.state.index > 0) {
        CMP.setState({ index : CMP.state.index - 1 })
    }
}

const next = () => {
    if(CMP.state.index < 5) {
        CMP.setState({ index : CMP.state.index + 1 })
    }
}

const play = index => {
    const video = document.querySelectorAll('.PortfolioInner video')[index]
    video.currentTime = 0
    video.play()
}

const pause = index => {
    const video = document.querySelectorAll('.PortfolioInner video')[index]
    video.pause()
}


let structure = [];

structure.push({
    name : 'BASQUIAT | The Immersive Experience',
    link : 'https://web-experiments.arimac.digital/immersive/projects/basquiat/',
    snap : 'images/portfolio/basquiat-phillips.jpg',
    play : 'images/portfolio/basquiat-phillips.mp4',
    info : <div>
            Virtually create your DREAM CAR using the WynkAR powered Car Configurator. Your design, your colour, your own unique looking car just the way you want it! Don’t forget to take a few snaps with you beside it. Yes, WynkAR can make this  dream of yours a reality using Web AR.<br /><br />
            Not only cars, any product you sell can be made available for customers to properly experience just like this. Reach us to get your own configurator done to attract more customers towards your brand.
            </div>
});

structure.push({
    name : 'Car Configurator | Virtually create your Dream Car',
    link : './portfolio/car-configurator',
    snap : 'images/portfolio/car-configurator.jpg',
    play : 'images/portfolio/car-configurator.mp4',
    info : <div>
            Virtually create your DREAM CAR using the WynkAR powered Car Configurator. Your design, your colour, your own unique looking car just the way you want it! Don’t forget to take a few snaps with you beside it. Yes, WynkAR can make this  dream of yours a reality using Web AR.<br /><br />
            Not only cars, any product you sell can be made available for customers to properly experience just like this. Reach us to get your own configurator done to attract more customers towards your brand.
            </div>
});

structure.push({
    name : 'VR Room | A Fully Fledged 3D Experience',
    link : './portfolio/vr-room/',
    snap : 'images/portfolio/vr-experience.jpg',
    play : 'images/portfolio/vr-experience.mp4',
    info : <div>
            A fully fledged 3D experience can now be made closer to you without any expensive devices. Enter WynkAR’s VR Room, walk around, interact with the objects and enjoy an amazing VR experience.<br /><br />
            Not only customizers and showrooms, WynkAR can help you take your advertising to the next level using the VR Room. Reach us to start your own branding journey using VR. 
            </div>
});

structure.push({
    name : 'Furniture Customizer',
    link : './portfolio/furniture-collection/',
    snap : 'images/portfolio/furniture-collection.jpg',
    play : 'images/portfolio/furniture-collection.mp4',
    info : <div>
        How about having a say in how your furniture should look like rather than making your mind to buy something available in the store? The design, the shape, the colour and everything related to your furniture can be decided and created all by yourself using the WynkAR powered Furniture Customizer. Whatever you design can be checked whether it actually looks good when placed in your living room using Web AR in WynkAR.<br /><br />
        Not only furniture, any product you sell can be made available for customers to check whether it actually suit them just like this. Reach us to get your own customizer done to attract more customers towards your brand. 
    </div>
});

structure.push({
    name : 'Virtual Showroom',
    link : './portfolio/virtual-exhibition/',
    snap : 'images/portfolio/virtual-exhibition.jpg',
    play : 'images/portfolio/virtual-exhibition.mp4',
    info : <div>
            Why wasting time going to showrooms when you can virtually visit showrooms. The WynkAR Virtual Showroom can give you the exact experience of visiting a physical showroom, look around for the products you want, get the help of salespersons, add them to a cart and purchase.<br /><br />
            Stop spending huge amounts on maintaining cozy looking showrooms. Start attracting more customers to your Virtual Showroom. Reach us to get started.
            </div>
});

structure.push({
    name : 'Dress Customizer',
    link : './portfolio/clothing-customizer/',
    snap : 'images/portfolio/clothing-customizer.jpg',
    play : 'images/portfolio/clothing-customizer.mp4',
    info : <div>
            Design, wear, design again, check again and repeat. Doesn’t matter how much long you take; doesn’t matter how many times you change your mind the WynkAR powered Dress Customizer won’t be staring at you.You can even just take a pic with the dress you design and then forget it completely. WynkAR can give you a luxury of a life time using Web AR.<br /><br />
            Not only clothes, any wearable or accessory you sell can be made available for customers to put on and check whether they suit them just like this. Reach us to get your own customizer done to attract more customers towards your brand.
            </div>
});

window.addEventListener('resize', () => {
    CMP.setState({ mobi : Date.now() })
})

export default Portfolio;