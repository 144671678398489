import { _args, EXPORTERS } from "../import";

let exportModel = function() {
    let args = _args(arguments);
    let obj = args.obj[0];
    let ext = args.str[0] || 'GLTF';
    let fnc = args.fun[0] || function() {};

    ext = ext.toUpperCase();
    function dne(blob) {
        fnc(URL.createObjectURL(blob));
    };

    if(ext === 'USDZ') {
        new EXPORTERS.USDZExporter().parse(obj).then(function(result) {
            dne(new Blob([result], { type: 'application/octet-stream' }));
        });
    }

    if(ext === 'GLTF') {
        new EXPORTERS.GLTFExporter().parse(obj, function(result) {
            if(result instanceof ArrayBuffer) {
                dne(new Blob([result], { type: 'application/octet-stream' }));
            } else {
                const output = JSON.stringify( result, null, 2 );
                dne(new Blob([output], { type: 'text/plain' }));
            }
        });
    }

};

export {
    exportModel
};