import { THREE } from '../import';

let XRWebGLLayer = window.XRWebGLLayer;

let checkAvailability = async (onSupport, onError) => {
    // each state check
    var req_1 = navigator.xr;
    var req_2 = navigator.xr.isSessionSupported;
    var req_3 = await navigator.xr.isSessionSupported("immersive-ar");
    if(req_1 && req_2 && req_3) {
        onSupport();
    } else { onError(); }
};

// request API session
let requestSession = async (element, callback) => {
    try {
        // initalize XR session
        const session = await navigator.xr.requestSession("immersive-ar", {
            requiredFeatures : ['hit-test', 'dom-overlay'],
            domOverlay : { root : element }
        });
        // next step
        configureCanvas(element, session, callback);
    } catch(e) { console.error(e); }
};

// create canvas and configure context
let configureCanvas = async (element, session, callback) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("webgl", { xrCompatible : true });
    session.updateRenderState({
      baseLayer : new XRWebGLLayer(session, context)
    });
    // next step
    await configureScene(
        element,
        session,
        canvas,
        context,
        callback
    );
};

// create Three.js modules and connect with context
let configureScene = async (element, session, canvas, context, callback) => {
    const scene  = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera();
    camera.matrixAutoUpdate = false;
    const renderer = new THREE.WebGLRenderer({
        alpha   : true,
        preserveDrawingBuffer : true,
        canvas  : canvas,
        context : context
    });

    // next step
    await configureLights(
        element,
        session,
        canvas,
        context,
        scene,
        camera,
        renderer,
        callback
    );
};

// add light system to scene
let configureLights = async (element, session, canvas, context, scene, camera, renderer, callback) => {
    const light = new THREE.AmbientLight(0xffffff, 0.5);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.3);
    directionalLight.position.set(10, 15, 10);
    // We want this light to cast shadow.
    directionalLight.castShadow = true;
    // Make a large plane to receive our shadows
    const planeGeometry = new THREE.PlaneGeometry(2000, 2000);
    // Rotate our plane to be parallel to the floor
    planeGeometry.rotateX(-Math.PI / 2);
    // Create a mesh with a shadow material, resulting in a mesh
    // that only renders shadows once we flip the `receiveShadow` property.
    const shadowMesh = new THREE.Mesh(planeGeometry, new THREE.ShadowMaterial({
      color: 0x111111,
      opacity: 0.2,
    }));
    // Give it a name so we can reference it later, and set `receiveShadow`
    // to true so that it can render our model's shadow.
    shadowMesh.name = 'shadowMesh';
    shadowMesh.receiveShadow = true;
    shadowMesh.position.y = 10000;
    // Add lights and shadow material to scene.
    scene.add(shadowMesh);
    scene.add(light);
    scene.add(directionalLight);
    // next step
    await configureReferenceSpaces(
        element,
        session,
        canvas,
        context,
        scene,
        camera,
        renderer,
        callback
    );
};

// strat WebXR API
let configureReferenceSpaces = async (element, session, canvas, context, scene, camera, renderer, callback) => {
    // Setup an XRReferenceSpace using the "local" coordinate system.
    const localReferenceSpace = await session.requestReferenceSpace('local');
    // Create another XRReferenceSpace that has the viewer as the origin.
    const viewerSpace = await session.requestReferenceSpace('viewer');
    // Perform hit testing using the viewer as origin.
    const hitTestSource = await session.requestHitTestSource({
        space : viewerSpace
    });
    // next step
    await callback({
        element : element,
        session : session,
        canvas : canvas,
        context : context,
        scene : scene,
        camera : camera,
        renderer : renderer,
        localReferenceSpace : localReferenceSpace,
        viewerSpace : viewerSpace,
        hitTestSource : hitTestSource
    });
};

let renderLoop = function(obj, onHitPoseUpdate = function() {}) {
    
    let session = obj.session;
    let scene = obj.scene;
    let camera = obj.camera;
    let renderer = obj.renderer;
    let context = obj.context;
    let localReferenceSpace = obj.localReferenceSpace;
    let hitTestSource = obj.hitTestSource;
    
    
    function render(time, frame) {
        session.requestAnimationFrame(render);
        // bind the graphics framebuffer to the baseLayer's framebuffer.
        const framebuffer = session.renderState.baseLayer.framebuffer;
        context.bindFramebuffer(context.FRAMEBUFFER, framebuffer);
        // get pose of the device
        const pose = frame.getViewerPose(localReferenceSpace);
        if(pose) {
            const view = pose.views[0];
            const viewport = session.renderState.baseLayer.getViewport(view);
            renderer.setSize(viewport.width, viewport.height)
            // update camera
            camera.matrix.fromArray(view.transform.matrix)
            camera.projectionMatrix.fromArray(view.projectionMatrix);
            camera.updateMatrixWorld(true);
            // get retical hit result
            const hitTestResults = frame.getHitTestResults(hitTestSource);
            if(hitTestResults.length > 0) {
            const hitPose = hitTestResults[0].getPose(localReferenceSpace);
                // callback position
                onHitPoseUpdate(hitPose);
            }
            // render to canvas
            renderer.render(scene, camera);
        }
    };
    // frame request loop
    session.requestAnimationFrame(render);
};

let immersivear = {
    checkAvailability,
    requestSession,
    renderLoop
};

export { immersivear };