import { Component } from 'react';
import './Contact.css';

class Contact extends Component {

	render() {
		return (
            <div className="ContactRoot">
            <div className="ContactInfo">
                <div className="ContactItem ContactItemAddress">
                    Arimac Lanka PVT LTD
                    No 2, 6th Lane,
                    Colombo 03
                    Sri Lanka.
                </div>
                <div className="ContactItem ContactItemMail">
                    hello@arimac.digital
                </div>
                <div className="ContactItem ContactItemHotline">
                    (+94) 115 219 554
                </div>
            </div>
                <div className="ContactMail">
                    <div className="ContactMailTitle">Contact Us</div>
                    <div className="ContactMailInner">
                        <form>
                            <input required type="text" placeholder="First Name" />
                            <input required type="text" placeholder="Last Name" />
                            <input required type="email" placeholder="Email Address" />
                            <input required type="text" placeholder="Mobile No." />
                            <input required type="text" placeholder="Company Name" />
                            <textarea required placeholder="Message"></textarea>
                        </form>
                        <button onClick={this.send}>Send Message</button>
                    </div>
                </div>
            </div>
        );
	};

    send() {
        const form = document.querySelector('form')
        if(form.checkValidity()) {
            const arr = Array.from(document.querySelectorAll('form > *'))
            const obj = {
                "firstname": arr[0].value,
                "lastname": arr[1].value,
                "email": arr[2].value,
                "message": arr[5].value,
                "phone": arr[3].value,
                "company": arr[4].value,
                "timestamp": new Date().toLocaleString()
            }
            const endpoint = `https://prod-01.southeastasia.logic.azure.com:443/workflows/b8b5c1db78eb4b9f930ff32b98eb5d30/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Bpt4QuSI29c9eRa5PTxLZhoqfkXT3ZArA_08yE19YYs`
            arr.forEach(x => x.value = '')
            fetch(endpoint, {
                method : 'POST',
                headers : { 'Content-Type': 'application/json' },
                body : JSON.stringify(obj)
            })
        } else {
            form.reportValidity()
        }
    }

};

export default Contact;