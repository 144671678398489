import { _args } from '../import';

function evalPoints() {
    var args = _args(arguments);
    let typ = args.str[0].toUpperCase();
    let obj, obj_1, obj_2, rnd, cam;
    if(typ === 'BETWEEN') {
        // variables
        obj_1 = args.obj[0];
        obj_2 = args.obj[1];
        let vec_1 = obj_1;
        let vec_2 = obj_2;
        // get points
        if(vec_1.position) { vec_1 = obj_1.position; }
        if(vec_2.position) { vec_2 = obj_2.position; }
        // calulation
        return Math.pow(
            Math.pow(vec_1.x - vec_2.x, 2) +
            Math.pow(vec_1.y - vec_2.y, 2) +
            Math.pow(vec_1.z - vec_2.z, 2), 0.5
        );
    } else if(typ === 'SCREEN') {
        // variables
        obj = args.obj[0];
        cam = args.obj[1];
        rnd = args.obj[2];
        // project

        // screen size
        let widthHalf = rnd.domElement.width / 2;
        let heightHalf = rnd.domElement.height / 2;
        // position
        let pos = obj.position.clone();
        pos.project(cam);
        pos.x = + (pos.x * widthHalf)  + widthHalf;
        pos.y = - (pos.y * heightHalf) + heightHalf;

        return pos;
    }
};

export {
    evalPoints
};