import { threejs } from './index';
import {
    THREE,
    POSTPROCESSING,
    CONTROLS,
    _args
} from '../import';

// ==========================================================================

let createScene = function() {
    var args = _args(arguments);
    var width  = args.num[0] || window.innerWidth;
    var height = args.num[1] || window.innerHeight;
    var color  = args.str[0];
    var auto   = args.boo[0];
    // modules
    var scene    = new THREE.Scene();
    var camera   = new THREE.PerspectiveCamera(45, width / height, 0.01, 1000);
    var renderer = new THREE.WebGLRenderer({ antialias : true, alpha : true });
    var controls = new CONTROLS.OrbitControls(camera, renderer.domElement);
    var light    = threejs.createLight('AMBIENT', '#FFF');
    // setup
    scene.add(light);
    renderer.setSize(width, height);
    if(color !== undefined) { scene.background = new THREE.Color(color); }
    else {  }
    // controls
    controls.enablePan       = false;
    controls.autoRotate      = false;
    controls.autoRotateSpeed = 0.8;
    controls.enableDamping   = true;
    controls.dampingFactor   = 0.1;
    threejs.setupControls('ORBIT', controls,
        { x : 0, y : 0, z : 0 },
        { x : 0, y : 0, z : 5 }
    );
    // render
    if(auto !== false) {
        function render() {
            requestAnimationFrame(render);
            controls.update();
            renderer.render(scene, camera);
        }; render();
    }

    let modules = {
        scene      : scene,
        camera     : camera,
        renderer   : renderer,
        controls   : controls,
        light      :light
    };

    // user events init
    createEvent.listen(modules);

    // return
    return modules;
};

// ==========================================================================

let createEffect = function() {
    var args = _args(arguments);
    var names    = args.str;
    var renderer = args.obj[0];
    var scene    = args.obj[1];
    var camera   = args.obj[2];
    var options  = args.obj[3] || {};

    var w = renderer.domElement.width;
    var h = renderer.domElement.height;

    // composer and render pass
    var pass;
    let composer = new POSTPROCESSING.EffectComposer(renderer);
    let render   = new POSTPROCESSING.RenderPass(scene, camera);
    composer.addPass(render);
    // output
    let out = { composer : composer, renderPass : render };
    // for each pass
    names.forEach(function(name) {
        name = name.toUpperCase();
        // select pass
        if(name === 'OUTLINE') {
            pass = new POSTPROCESSING.OutlinePass(new THREE.Vector2(w, h), scene, camera);
            pass.edgeStrength = 3;
            pass.visibleEdgeColor.set('#FFFFFF');
        } else if(name === 'BLOOM') {
            pass = new POSTPROCESSING.UnrealBloomPass();
        } else if(name === 'GLITCH') {
            pass = new POSTPROCESSING.GlitchPass();
        } else if(name === 'SSAO') {
            pass = new POSTPROCESSING.SSAOPass(scene, camera, w, h);
            pass.kernelRadius = 0.2;
            pass.minDistance = 0.0001;
            pass.maxDistance = 0.3;
        } else if(name === 'SSR') {
            pass = new POSTPROCESSING.SSRPass(render, scene, camera, w, h);
        } else if(name === 'BOKEH') {
            pass = new POSTPROCESSING.BokehPass(scene, camera, {
                focus: 4.0,
                aperture: 0.0001,
                maxblur: 0.01,
                width: w, height: h
            });
        } else { return; }
        // options
        Object.keys(options).forEach(function(opt) {
            if(options[opt] === undefined) { return; }
            if(opt === 'visibleEdgeColor') {
                pass[opt].set(options[opt]);
            } else {
                pass[opt] = options[opt];
            }
        });
        // add pass
        composer.addPass(pass);
    });
    return out;
};

// ==============================================================================

let createEvent = function() {
    var args = _args(arguments);
    var type     = args.str[0].toLowerCase();
    var object   = arguments[1];
    var callback = args.fun[0];

    if(Array.isArray(object) === false) {
        object = [object];
    }

    var arr = createEvent.list;
    object.forEach(function(obj) {
        if(arr[type]) {
            if(arr[type][obj.uuid] === undefined) {
                arr[type][obj.uuid] = [];
            }
            arr[type][obj.uuid].push(callback);
        }
    });

};

createEvent.list = {
    'click'      : {},
    'mousedown'  : {},
    'mouseup'    : {},
    'mousemove'  : {},
    'mouseenter' : {},
    'mouseleave' : {}
};

createEvent.down = false;

createEvent.last = { object : {} };

createEvent.listen = function(modules) {
    let u = createEvent;
    let cnv = modules.renderer.domElement;
    cnv.addEventListener('mousedown', function(e) {
        u.down = true;
        // mousedown
        var obj = createEvent.cast(e, modules);
        if(obj === null) { return; }
        else { createEvent.callback('mousedown', obj, e); }
    });
    cnv.addEventListener('mousemove', function(e) {
        u.down = false;
        if(u.down === false) {
            // mousemove
            var obj = createEvent.cast(e, modules);
            if(obj === null) { return; }
            else {
                createEvent.callback('mousemove', obj, e);
                if(u.last.object.uuid !== obj.object.uuid) {
                    // mouseleave
                    createEvent.callback('mouseleave', u.last, e);
                    u.last = obj;
                    // mouseenter
                    createEvent.callback('mouseenter', obj, e);
                };
            }
        }
    });
    cnv.addEventListener('mouseup', function(e) {
        if(u.down) {
            // click
            var obj = createEvent.cast(e, modules);
            if(obj === null) { return; }
            else { createEvent.callback('click', obj, e); }
        }
        u.down = false;
    });
};

createEvent.cast = function(event, modules) {
    // positions
    let sz = modules.renderer.domElement.getBoundingClientRect();

    let xy = new THREE.Vector2();
    xy.x = +(event.layerX / sz.width) * 2 - 1;
    xy.y = -(event.layerY / sz.height) * 2 + 1;

    // raycaster
    let caster = new THREE.Raycaster();
    caster.setFromCamera(xy, modules.camera);
    let arr = threejs.findByType(modules.scene, 'Mesh', true);
    let out = caster.intersectObjects(arr);
    if(out.length > 0) {
        return out[0];
    } else { return null; }
};

createEvent.callback = function(type, data, event) {
    var uuid = data.object.uuid;
    var list = createEvent.list[type];
    if(list[uuid] === undefined) { return; }
    else {
        list[uuid].forEach(function(f) { f(data, event); });
    }
};

// ==========================================================================

let createObject = function() {
    var args = _args(arguments);
    var data = args.obj[0] || {};
    var opts = args.obj[1];

    var grr = [
        'Box',
        'Circle',
        'Cone',
        'Cylinder',
        'Dodecahedron',
        'Edges',
        'Extrude',
        'Icosahedron',
        'Lathe',
        'Octahedron',
        'Parametric',
        'Plane',
        'Polyhedron',
        'Ring',
        'Shape',
        'Sphere',
        'Tetrahedron',
        'Text',
        'Torus',
        'TorusKnot',
        'Tube',
        'Wireframe'
    ];

    var mrr = [
        'LineBasic',
        'LineDashed',
        'MeshBasic',
        'MeshDepth',
        'MeshDistance',
        'MeshLambert',
        'MeshMatcap',
        'MeshNormal',
        'MeshPhong',
        'MeshPhysical',
        'MeshStandard',
        'MeshToon',
        'Points',
        'RawShader',
        'Shader',
        'Shadow',
        'Sprite'
    ];

    var g = null, m = null;
    Object.keys(data).forEach(function(key) {
        if(grr.indexOf(key) > -1) { g = key; };
        if(mrr.indexOf(key) > -1) { m = key; };
    }); if(g === null || m === null) { return; }

    var vrr = Object.values(data[g]);

    g += 'Geometry'

    var geo;
    if(vrr.length === 0) {
        geo = new THREE[g]();
    } else if(vrr.length === 1) {
        geo = new THREE[g](vrr[0]);
    } else if(vrr.length === 2) {
        geo = new THREE[g](vrr[0], vrr[1]);
    } else if(vrr.length === 3) {
        geo = new THREE[g](vrr[0], vrr[1], vrr[2]);
    } else if(vrr.length === 4) {
        geo = new THREE[g](vrr[0], vrr[1], vrr[2], vrr[3]);
    } else if(vrr.length === 5) {
        geo = new THREE[g](vrr[0], vrr[1], vrr[2], vrr[3], vrr[5]);
    }

    var mat = new THREE[m + 'Material'](data[m]);

    var mesh = new THREE.Mesh(geo, mat);
    if(opts) { mesh = threejs.setupObject(mesh, opts); }
    return mesh;
};

let createLight = function() {
    var args = _args(arguments);
    var type  = args.str[0] || 'DIRECT';
    var clr   = args.str[1] || '#FFF';
    var int   = args.num[0] || 1;
    var pwr   = args.num[1];
    var pos   = args.obj[0];
    var rot   = args.obj[1];
    var vsb   = args.boo[0];

    clr = new THREE.Color(clr).getHex();
    type = type.toUpperCase();

    var light;
    if(type === 'DIRECT') {
        light = new THREE.DirectionalLight(clr, int);
    } else if(type === 'AMBIENT') {
        light = new THREE.AmbientLight(clr, int);
    } else if(type === 'POINT') {
        light = new THREE.PointLight(clr, int);
    } else if(type === 'HEMISPHERE') {
        light = new THREE.HemisphereLight(clr, int);
    } else if(type === 'SPOT') {
        light = new THREE.SpotLight(clr);
    }
    
    if(pwr !== undefined) { light.power = pwr; };
    if(pos !== undefined) { light.position.set(pos.x, pos.y, pos.z); };
    if(rot !== undefined) { light.rotation.set(rot.x, rot.y, rot.z); };
    if(rot !== undefined) { light.rotation.set(rot.x, rot.y, rot.z); };
    if(vsb !== undefined) { light.visible = vsb };

    return light;
};

// ==========================================================================

let createParticles = function() {
    var args = _args(arguments);
    var stat = args.str[0] || "";
    var rots = args.str[1] || "";
    var ax   = args.num[0];
    var bx   = args.num[1];
    var ay   = args.num[2];
    var by   = args.num[3];
    var az   = args.num[4];
    var bz   = args.num[5];
    var dx   = args.num[6] || 1;
    var dy   = args.num[7] || 1;
    var dz   = args.num[8] || 1;
    var objs = args.obj;

    if(Array.isArray(objs[0])) { objs = objs[0]; }

    ax = Math.min(ax, bx); bx = Math.max(ax, bx);
    ay = Math.min(ay, by); by = Math.max(ay, by);
    az = Math.min(az, bz); bz = Math.max(az, bz);

    stat = stat.toUpperCase();
    rots = rots.toUpperCase();

    function randNumber(a, b) {
        return (Math.random() * (Math.max(a, b) - Math.min(a, b))) + Math.min(a, b);
    };

    function randObject() {
        return objs[Math.floor(Math.random() * objs.length)];
    };

    var out = [];

    for(var x = ax; x <= bx; x += dx) {
        for(var y = ay; y <= by; y += dy) {
            for(var z = az; z <= bz; z += dz) {
                // position
                var rx = x, ry = y, rz = z;
                if(stat.indexOf('X') > -1) { rx = randNumber(ax, bx); }
                if(stat.indexOf('Y') > -1) { ry = randNumber(ay, by); }
                if(stat.indexOf('Z') > -1) { rz = randNumber(az, bz); }
                // rotation
                var ra = 0, rb = 0, rc = 0;
                if(rots.indexOf('X') > -1) { ra = Math.random(); }
                if(rots.indexOf('Y') > -1) { rb = Math.random(); }
                if(rots.indexOf('Z') > -1) { rc = Math.random(); }
                // clone and setup
                var robj = randObject();
                var obj = robj.clone();
                obj.material = robj.material.clone();
                obj.position.set(rx, ry, rz);
                obj.rotation.set(ra, rb, rc);
                out.push(obj);
            };
        };
    };

    return out;

};

// ==========================================================================

export {
    createScene,
    createEffect,
    createEvent,
    createLight,
    createObject,
    createParticles
};