import {
    _args
} from '../import';

// ==========================================================================

let findAll = function() {
    var args = _args(arguments);
    var model = args.obj[0];
    var out = [];
    function recMesh(obj) {
        out.push(obj);
        if(obj.children !== undefined) {
            for(var i = 0; i < obj.children.length; i++) {
                recMesh(obj.children[i]);
            }
        }
    }; recMesh(model);
    return out;
};

let findById = function() {
    var args = _args(arguments);
    var model = args.obj[0];
    var name  = args.str[0];
    var mult  = args.boo[0];

    var out = [];
    function recMesh(obj) {
        if(obj.uuid === name) {
            out.push(obj);
        }
        if(obj.children !== undefined) {
            for(var i = 0; i < obj.children.length; i++) {
                recMesh(obj.children[i]);
            }
        }
    }; recMesh(model);

    if(mult) { return out; }
    else { return out[0]; }
};

let findByName = function() {
    var args = _args(arguments);
    var model = args.obj[0];
    var name  = args.str[0];
    var mult  = args.boo[0];

    var out = [];
    function recMesh(obj) {
        if(obj.name === name) {
            out.push(obj);
        }
        if(obj.children !== undefined) {
            for(var i = 0; i < obj.children.length; i++) {
                recMesh(obj.children[i]);
            }
        }
    }; recMesh(model);

    if(mult) { return out; }
    else { return out[0]; }
};

let findByMaterialName = function() {
    var args = _args(arguments);
    var model = args.obj[0];
    var name  = args.str[0];
    var mult  = args.boo[0];

    var out = [];
    function recMesh(obj) {
        if(obj.material !== undefined) {
            if(obj.material.name === name) {
                out.push(obj);
            }
        }
        if(obj.children !== undefined) {
            for(var i = 0; i < obj.children.length; i++) {
                recMesh(obj.children[i]);
            }
        }
    }; recMesh(model);

    if(mult) { return out; }
    else { return out[0]; }
};

let findByType = function() {
    var args = _args(arguments);
    var model = args.obj[0];
    var type  = args.str[0];
    var mult  = args.boo[0];

    var out = [];
    function recMesh(obj) {
        if(obj.type === type) {
            out.push(obj);
        }
        if(obj.children !== undefined) {
            for(var i = 0; i < obj.children.length; i++) {
                recMesh(obj.children[i]);
            }
        }
    }; recMesh(model);

    if(mult) { return out; }
    else { return out[0]; }
};

let findByProperty = function() {
    var args = _args(arguments);
    var model = args.obj[0];
    var data  = args.obj[1];
    var mult  = args.boo[0];

    var out = [];
    function recMesh(obj) {
        Object.keys(data).forEach(function(key) {
            if(obj[key] === data[key]) { out.push(obj); }
        });
        if(obj.children !== undefined) {
            for(var i = 0; i < obj.children.length; i++) {
                recMesh(obj.children[i]);
            }
        }
    }; recMesh(model);

    if(mult) { return out; }
    else { return out[0]; }
};

let findByMaterialProperty = function() {
    var args = _args(arguments);
    var model = args.obj[0];
    var data  = args.obj[1];
    var mult  = args.boo[0];

    var out = [];
    function recMesh(obj) {
        if(obj.material !== undefined) {
            Object.keys(data).forEach(function(key) {
                if(obj.material[key] === data[key]) { out.push(obj); }
            });
        }
        if(obj.children !== undefined) {
            for(var i = 0; i < obj.children.length; i++) {
                recMesh(obj.children[i]);
            }
        }
    }; recMesh(model);

    if(mult) { return out; }
    else { return out[0]; }
};

// ==========================================================================

export {
    findAll,
    findById,
    findByName,
    findByType,
    findByProperty,
    findByMaterialName,
    findByMaterialProperty
};