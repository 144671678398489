/**
 *  ChatJS v2.0 by Deshan Nawanjana
 *  https://dnjs.info/
 *  deshan.nawanjana@arimaclanka.com
 *  Copyright © 2021 Deshan Nawanjana <dnjs.info@gmail.com>
**/

// queries => keylist[% > 75] => keylist[max(v) => null]

let symbols = Array.from(`\`1234567890-=[];'\\,./~!@#$%^&*()_+{}:"|<>?`);

function replaceAll(x, a, b) {
    while(x.indexOf(a) > -1) { x = x.replace(a, b); }
    return x;
};

function removeSymbols(txt) {
    symbols.forEach(function(s) {
        txt = replaceAll(txt, s, ' ');
    });
    txt = replaceAll(txt, '  ', ' ');
    return txt;
};

function getCheckArray(txt) {
    let out = [];
    removeSymbols(txt).toLowerCase().split(' ').forEach(function(k) {
        if(k !== '') { out.push(k); }
    });
    return out;
};

function scoreKeylist(txt, obj) {
    // simplify input
    txt = getCheckArray(txt);
    // values
    let max_v = 0, idx_v = -1;
    let max_p = 0, idx_p = -1;
    // score loop
    obj.forEach(function(dat, i) {
        if(dat.filter.keylist === undefined) { return; }
        let lst = dat.filter.keylist;
        let tot = 0;
        txt.forEach(function(w) {
            if(lst.indexOf(w) > -1) {tot++; } else {
                if(w[w.length - 1] === 's') {
                    if(lst.indexOf(w.substr(0, w.length - 1)) > -1) {tot++; }
                }
            }
        });
        let prc = 100 * tot / lst.length;
        if(tot > max_v) { max_v = tot; idx_v = i; }
        if(prc > max_p) { idx_p = prc; idx_p = i; }
    });
    // no match
    if(idx_v === -1 && idx_p === -1) { return null; }
    // check
    if(max_p > 75) {
        return obj[idx_p];
    } else if(idx_v !== -1) {
        return obj[idx_v];
    } else {
        return null;
    }
};

function scoreQueries(txt, obj) {
    // simplify input
    txt = getCheckArray(txt);
    // value
    let idx = -1;
    // score loop
    obj.forEach(function(dat, i) {
        if(dat.filter.queries === undefined) { return; }
        dat.filter.queries.forEach(function(q) {
            let all = true;
            q.split(' ').forEach(function(w) {
                if(txt.indexOf(w) === -1) { all = false; }
            });
            if(all) { idx = i; }
        });
    });
    // check
    if(idx === -1) {
        return null;
    } else {
        return obj[idx];
    }
};

let ChatJS = function() {

    // data list
    this.AIData = [];

    // add new item to list
    this.add = function(answer, filter) {
        this.AIData.push({ answer : answer, filter : filter })
    };

    // question validation check
    this.validate = function(txt) {
        txt = removeSymbols(txt.toLowerCase());
        return (txt !== ' ' && txt !== '');
    };

    this.ask = function(txt) {
        let sq = scoreQueries(txt, this.AIData);
        let sk = scoreKeylist(txt, this.AIData);
        if(sq !== null) {
            return {
                question : txt,
                answer   : sq.answer,
                addtion  : sq.filter.addtion
            };
        } else if(sk !== null) {
            return {
                question : txt,
                answer   : sk.answer,
                addtion  : sk.filter.addtion
            };
        } else {
            return null;
        }
    };

};

export default ChatJS;