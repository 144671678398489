import { Component } from 'react';
import './Global.css';

let GUI

class Global extends Component {

	constructor(props) {
		super(props)
		this.state = { video : null, icase : 0 }
		GUI = this
	}

	buildUseCasesList(items) {
		let arr = []
		items.forEach((itm, i) => {
			arr.push(
				<div
					className="GlobalUseCaseItemListItem"
					key={i}
				>{ itm }</div>
			)
		})
		return arr
	}

	buildUseCases() {
		let cmp = this
		let arr = []

		let itm = structure[GUI.state.icase]
		arr.push(
			<div
				className="GlobalUseCaseItemOuter"
				key={GUI.state.icase}
			>

				<div className="GlobalUseCaseItem">
					<div className="GlobalUseCaseItemTitle">{ itm.title }</div>
					<div
						className="GlobalUseCaseItemInner"
						style={ { backgroundImage: itm.imsvg } }
					>
						<div className="GlobalUseCaseItemParag">{ itm.parag }</div>
						<div className="GlobalUseCaseItemItems">
							{ cmp.buildUseCasesList(itm.items) }
						</div>
					</div>
				</div>

				<div className="GlobalUseCaseButtons">
					<button onClick={lastCaseItem}>Back</button>
					<button
						onClick={() => { openVideo(itm.video) }}
					>Play Video</button>
					<button onClick={nextCaseItem}>Next</button>
				</div>

			</div>
		)
		
		return arr
	}

	buildClients() {
		let arr = []
		structure_clients.forEach((itm, i) => {
			let css = { backgroundImage : `url(${itm.image})` }
			arr.push(
				<div className="GlobalClientsItem" style={css} key={i}></div>
			)
		})
		return arr
	}

	buildVideoPlayer() {
		if(GUI.state.video === null) { return [] }
		return (
			<div
				className="GlobalPlayer"
				onClick={() => { GUI.setState({ video : null }) }}
			>
				<div
					className="GlobalPlayerClose"
					onClick={() => { GUI.setState({ video : null }) }}
				></div>
				<video
					src={GUI.state.video}
					autoPlay={true}
					controls
				></video>
			</div>
		)
	}

	render() {
		return (
				<div className="GlobalRoot">
					<div className="GlobalUseCaseRoot">
						<div className="GlobalUseCaseTitle">Use Cases</div>
						<center>{ this.buildUseCases() }</center>
					</div>
					<div className="GlobalClientsRoot">
						<div className="GlobalClientsTitle">Our Clients</div>
						<center>{ this.buildClients() }</center>
					</div>
					{ this.buildVideoPlayer() }
				</div>
        )
	}

}

let openVideo = url => {
	GUI.setState({ video : url })
}

let lastCaseItem = () => {
	let x = GUI.state.icase
	x === 0
		? x = structure.length - 1
		: x--
	GUI.setState({ icase : x })
}

let nextCaseItem = () => {
	let x = GUI.state.icase
	x === structure.length - 1
		? x = 0
		: x++
	GUI.setState({ icase : x })
}

let structure = []

structure.push({
	title : "3D Configurator",
	parag : "Sell more by letting your customers create amazing products in real time. Configure in 3D and place in AR. High-quality 3D Configurator with direct support for Augmented Reality.",
	items : [
		"Allows the customer visualize and customize the products",
		"Stay ahead of your competitors with exciting customer experience",
		"Sell more by letting your customers create amazing products in real time",
		"Improve your conversion rate with 3D configurator"
	],
	video : "videos/wynkar_2.mp4",
	image : "global/1.jpg",
	imsvg : `url("data:image/svg+xml,<svg id='svg' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style='transform: rotate(0deg); fill: rgba(249, 72, 72, 1);'><path d='M7.52 21.48C4.25 19.94 1.91 16.76 1.55 13H.05C.56 19.16 5.71 24 12 24l.66-.03-3.81-3.81-1.33 1.32zm.89-6.52c-.19 0-.37-.03-.52-.08-.16-.06-.29-.13-.4-.24-.11-.1-.2-.22-.26-.37-.06-.14-.09-.3-.09-.47h-1.3c0 .36.07.68.21.95.14.27.33.5.56.69.24.18.51.32.82.41.3.1.62.15.96.15.37 0 .72-.05 1.03-.15.32-.1.6-.25.83-.44s.42-.43.55-.72c.13-.29.2-.61.2-.97 0-.19-.02-.38-.07-.56-.05-.18-.12-.35-.23-.51-.1-.16-.24-.3-.4-.43-.17-.13-.37-.23-.61-.31.2-.09.37-.2.52-.33.15-.13.27-.27.37-.42.1-.15.17-.3.22-.46.05-.16.07-.32.07-.48 0-.36-.06-.68-.18-.96-.12-.28-.29-.51-.51-.69-.2-.19-.47-.33-.77-.43C9.1 8.05 8.76 8 8.39 8c-.36 0-.69.05-1 .16-.3.11-.57.26-.79.45-.21.19-.38.41-.51.67-.12.26-.18.54-.18.85h1.3c0-.17.03-.32.09-.45s.14-.25.25-.34c.11-.09.23-.17.38-.22.15-.05.3-.08.48-.08.4 0 .7.1.89.31.19.2.29.49.29.86 0 .18-.03.34-.08.49-.05.15-.14.27-.25.37-.11.1-.25.18-.41.24-.16.06-.36.09-.58.09H7.5v1.03h.77c.22 0 .42.02.6.07s.33.13.45.23c.12.11.22.24.29.4.07.16.1.35.1.57 0 .41-.12.72-.35.93-.23.23-.55.33-.95.33zm8.55-5.92c-.32-.33-.7-.59-1.14-.77-.43-.18-.92-.27-1.46-.27H12v8h2.3c.55 0 1.06-.09 1.51-.27.45-.18.84-.43 1.16-.76.32-.33.57-.73.74-1.19.17-.47.26-.99.26-1.57v-.4c0-.58-.09-1.1-.26-1.57-.18-.47-.43-.87-.75-1.2zm-.39 3.16c0 .42-.05.79-.14 1.13-.1.33-.24.62-.43.85-.19.23-.43.41-.71.53-.29.12-.62.18-.99.18h-.91V9.12h.97c.72 0 1.27.23 1.64.69.38.46.57 1.12.57 1.99v.4zM12 0l-.66.03 3.81 3.81 1.33-1.33c3.27 1.55 5.61 4.72 5.96 8.48h1.5C23.44 4.84 18.29 0 12 0z'></path></svg>")`
})

structure.push({
	title : "WynkAR on everyday objects",
	parag : "Blur the lines between reality and the virtual world by allowing everyday objects to spring to life with depth and motion.",
	items : [
		"Product demos",
		"Product packaging & labelling",
		"Consumer promos",
		"Employee onboarding packs",
		"Delegate packs"
	],
	video : "videos/wynkar_1.mp4",
	image : "global/2.jpg",
	imsvg : `url("data:image/svg+xml,<svg id='svg' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style='transform: rotate(0deg); fill: rgba(249, 72, 72, 1);'><path d='M19 9l1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25L19 9zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25L19 15z'></path></svg>")`
})

structure.push({
	title : "WynkAR over print material",
	parag : "Redefine your marketing and advertising strategies by elevating mere print adverts to full-on animations that will resonate with your audience, build brand awareness, boost engagement and deliver results.",
	items : [
		"Press adverts",
		"Point of sale materials",
		"Catalogues",
		"Magazines and lot more"
	],
	video : "videos/wynkar_2.mp4",
	image : "global/3.jpg",
	imsvg : `url("data:image/svg+xml,<svg id='svg' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' style='transform: rotate(0deg); fill: rgba(249, 72, 72, 1);'><path d='M6,2.5H3.5V5H2V1h4V2.5z M16.5,5l0-2.5l-2.5,0L14,1l4,0l0,4L16.5,5z M14,17.5h2.5V15H18v4h-4V17.5z M3.5,15l0,2.5l2.5,0 L6,19l-4,0l0-4L3.5,15z M13.57,4H6.43C5.64,4,5,4.67,5,5.5v9C5,15.33,5.64,16,6.43,16h7.14c0.79,0,1.43-0.67,1.43-1.5v-9 C15,4.67,14.36,4,13.57,4z M12,13H8v-1.5h4V13z M12,10.75H8v-1.5h4V10.75z M12,8.5H8V7h4V8.5z'></path></svg>")`
})

structure.push({
	title : "View your products in Augmented Reality",
	parag : "",
	items : [
		"Promote your brand with augmented reality",
		"level up your customer engagement",
		"Let the customer feel and experience your product with AR",
		"Empower your brand campaign"
	],
	video : "videos/wynkar_1.mp4",
	image : "global/4.jpg",
	imsvg : `url("data:image/svg+xml,<svg id='svg' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style='transform: rotate(0deg); fill: rgba(249, 72, 72, 1);'><path d='M15.54 5.54L13.77 7.3 12 5.54 10.23 7.3 8.46 5.54 12 2zm2.92 10l-1.76-1.77L18.46 12l-1.76-1.77 1.76-1.77L22 12zm-10 2.92l1.77-1.76L12 18.46l1.77-1.76 1.77 1.76L12 22zm-2.92-10l1.76 1.77L5.54 12l1.76 1.77-1.76 1.77L2 12z'></path><path cx='12' cy='12' r='3'></path><circle d='M15.54 5.54L13.77 7.3 12 5.54 10.23 7.3 8.46 5.54 12 2zm2.92 10l-1.76-1.77L18.46 12l-1.76-1.77 1.76-1.77L22 12zm-10 2.92l1.77-1.76L12 18.46l1.77-1.76 1.77 1.76L12 22zm-2.92-10l1.76 1.77L5.54 12l1.76 1.77-1.76 1.77L2 12z'></circle><circle cx='12' cy='12' r='3'></circle></svg>")`
})

let structure_clients = []

structure_clients.push({
	title : 'Nestle',
	image : 'global/clients/nestle.jpg'
})

structure_clients.push({
	title : 'Dialog',
	image : 'global/clients/dialog.png'
})

structure_clients.push({
	title : 'Tesco',
	image : 'global/clients/tesco.png'
})

structure_clients.push({
	title : 'Dhiraagu',
	image : 'global/clients/dhiraagu.jpg'
})

structure_clients.push({
	title : 'Harpos',
	image : 'global/clients/harpos.png'
})

structure_clients.push({
	title : 'Mobitel',
	image : 'global/clients/mobitel.jpg'
})

structure_clients.push({
	title : 'NDB',
	image : 'global/clients/ndb.jpg'
})

structure_clients.push({
	title : 'Unilever',
	image : 'global/clients/unilever.png'
})

structure_clients.push({
	title : 'CBL',
	image : 'global/clients/cbl.jpg'
})

export default Global