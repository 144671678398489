import { Component } from 'react';
import { TrinityEngine } from '../engine';
import './Cover.css';

let GL = TrinityEngine['three.js'];

class Cover extends Component {

	componentDidMount() {
		let root = document.querySelector('.CoverCanvas');
		root.appendChild(modules.renderer.domElement);
	}

	render() {
		return (
			<div className="CoverRoot">
				<div className="CoverCanvas">
					<div className="CoverMobileFrame">
						<div className="CoverMobileImage">
							
						</div>
					</div>
				</div>
				<div className="CoverContent">
					<div className="CoverContentTitle">
						Revolutionize Your <br />
						<div className="big">Imagination...</div>
					</div>
					<div className="CoverContentButton" onClick={scrollToAbout}>
						Get Started
					</div>
				</div>
			</div>
		);
	};

};

// =============================================================================

// create three modules
let modules = GL.createScene(false);

// locked controls setup
GL.setupControls('EXACT', modules.controls,
	{ x : 0, y : 0, z : 0 },
	{ x : 7.783291563780238, y : 9.758047330193127, z : 38.00264312827315 },
	{ enabled : false }
);

// window resize update
function setupScene() {
	let w = window.innerWidth;
	let h = window.innerHeight - 70;
	if(h > w) { h = w; }
	GL.setupScene(modules.renderer, modules.camera, w, h);
};

// window event listeners
window.addEventListener('resize', setupScene);
window.addEventListener('load', setupScene);

// =============================================================================

// spread area
let xa = -12, xb = +60;

// animate speeds
let fwdSPD = 0.1, rotSPD = 0.0026;

// load texture
let tex = GL.loadTexture('images/cover/wynkar_icon.png', function() {
	modules.renderer.domElement.style.opacity = 1;
});

// create boxes in five sizes
let knd = [];
[1, 1.5, 2, 3, 6.5].forEach(function(s) {
	knd.push(GL.createObject({
		'Box' : [s, s, s],
		'MeshBasic' : { map : tex, transparent : true }
	}));
});

// create particle system
let brr = GL.createParticles("XYZ",
	xa, xb, -20, +20, -15, +3,
	10, 15, 5,
	knd
);

// for each particle
brr.forEach(function(box) {
	// random start angle
	box.rotation.set(Math.random(), Math.random(), Math.random());
	// random rotate direction
	setRandRotation(box);
	// add to scene
	modules.scene.add(box)
});

// random rotation direction
function setRandRotation(box) {
	box.userData['rotX'] = [-1, 1][Math.floor(Math.random() * 2)];
	box.userData['rotY'] = [-1, 1][Math.floor(Math.random() * 2)];
	box.userData['rotZ'] = [-1, 1][Math.floor(Math.random() * 2)];
};

// animation loop
function render() {
	requestAnimationFrame(render);
	brr.forEach(function(box) {
		// forward
		box.position.x -= fwdSPD;
		// opacity
		box.material.opacity = (box.position.x - xa) / 65;
		// rotate
		box.rotation.set(
			box.rotation.x + rotSPD * box.userData['rotX'],
			box.rotation.y + rotSPD * box.userData['rotY'],
			box.rotation.z + rotSPD * box.userData['rotZ']
		);
		if(box.position.x < xa) {
			// reset position
			box.position.x = xb;
			// new random rotation direction
			setRandRotation(box);
		}
	});
	// render
	modules.renderer.render(modules.scene, modules.camera);
};

// start animation
render();

function scrollToAbout() {
    let element = document.querySelector('.AboutRoot');
    if(window['ScrollJS']) {
        window['ScrollJS'].setThis(element);
    }
};

export default Cover;