import { Component } from 'react';
import './Started.css';

class Started extends Component {

	render() {
        let arr = structure.map((obj, i) => {
            let svg = `url(images/started/${ (i + 1) }.svg)`
            return (
                <div
                    className="StartedItem"
                    key={i}
                    style={ { backgroundImage : svg } }
                >{ obj.text }
                </div>
            )
        })
		return (
            <div className="StartedRoot">
                <div className="StartedTitle">Get Started</div>
                <div className="StartedSubTitle">
                    A guide to how WynkAR works...
                </div>
                <center>{ arr }</center>
            </div>
        )
	}

}

let structure = []

structure.push({
    text : "Development of 3D environments and objects"
})

structure.push({
    text : "Animation and programming interactions with 3D objects"
})

structure.push({
    text : "Creating a web page and placing WebAR objects on it"
})

structure.push({
    text : "A customer sees a link or a QR code on the website or in an advertisement"
})

structure.push({
    text : "The user goes to the page and gives it access to the phone's camera"
})

structure.push({
    text : "Magic! An interactive AR image of the product appears next to the user in the space"
})

export default Started